/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */

 import React from 'react'
 import { 
   ApolloProvider,
   ApolloClient,
   split,
   HttpLink,
   InMemoryCache,
 } from '@apollo/client';
 import { getMainDefinition } from '@apollo/client/utilities';
 import fetch from 'isomorphic-fetch'
 import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
 import { createClient } from 'graphql-ws';

 const customFetch = (uri, options) => {
   if (uri.includes('dashusersession')) {
     const cid = (Math.random() + 1).toString(36).replace(/[^a-z]+/ig, "")
     options.headers['x-dash-user-request'] = cid
   }
   return fetch(uri, options);
 }

 const httpLink = new HttpLink({
   fetch: customFetch,
   uri: 'https://' + process.env.GATSBY_SITE_DOMAIN + '/graphql',
   fetchOptions: {
     method: 'GET'
   }
 });
 
 // typeof check determines whether we're clientside before choosing ws, otherwise, split will throw an error
 // this used to be handled with a check for process.browser, but that's been removed in later versions of Gatsby.
 // see info at https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v2-to-v3/#process-is-not-defined
 
 const wsLink = (typeof window !== 'undefined') ? new GraphQLWsLink(createClient({
   url: `wss://${process.env.GATSBY_SITE_DOMAIN}/subscriptions`,
   retryAttempts: 10
 })) : null;
 
 const splitLink = (typeof window !== 'undefined') ? split(
   ({ query }) => {
     const definition = getMainDefinition(query);
     return (
       definition.kind === 'OperationDefinition' &&
       definition.operation === 'subscription'
     );
   },
   wsLink,
   httpLink,
 ): httpLink;
 
 //set merge to be false to always use the incoming data
 const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            data: {
              merge: false,
            }
          }
        },
        Subscription: {
          fields: {
            data: {
              merge: false,
            }
          }
        }
      }
   }),
   link: splitLink,
 });
 
 export const wrapRootElement = ({element}) => (
   <ApolloProvider client={client}>{element}</ApolloProvider>
 )